import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import noop from 'lodash/noop';

import config from '../data/config';

import twitterIcon from '../images/twitter-icon-pink.svg';
import instagramIcon from '../images/instagram-icon-pink.svg';

class MobileMenu extends PureComponent {
  render() {
    const { scrollIntoView } = this.props;
    const offset = 80;

    return (
      <nav
        className="mobile-menu"
        itemScope
        itemType="https://schema.org/SiteNavigationElement"
      >
        <ul>
          <li itemProp="name">
            <button type="button" onClick={() => scrollIntoView('about', offset)}>
              <FormattedMessage id="about" />
            </button>
          </li>
          <li itemProp="name">
            <button type="button" onClick={() => scrollIntoView('action', offset)}>
              <FormattedMessage id="takeAction" />
            </button>
          </li>
          <li itemProp="name">
            <button type="button" onClick={() => scrollIntoView('how', offset)}>
              <FormattedMessage id="howItWorks" />
            </button>
          </li>
          <li itemProp="name">
            <button type="button" onClick={() => scrollIntoView('chart', offset)}>
              <FormattedMessage id="factsAndFigures" />
            </button>
          </li>
        </ul>
        <div className="social-links" itemScope itemType="https://schema.org/Organization">
          <a href={`${config.TinderInstagramUrl}`} target="_blank" rel="noopener noreferrer" itemProp="sameAs">
            <img alt="Instagram" src={instagramIcon} />
          </a>
          <a href={`${config.TinderTwitterUrl}`} target="_blank" rel="noopener noreferrer" itemProp="sameAs">
            <img alt="Twitter" src={twitterIcon} />
          </a>
        </div>
      </nav>
    );
  }
}

MobileMenu.propTypes = {
  scrollIntoView: PropTypes.func
};

MobileMenu.defaultProps = {
  scrollIntoView: noop
};

export default MobileMenu;
