import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CSSTransitionGroup } from 'react-transition-group';
import noop from 'lodash/noop';

import hamburgerIcon from '../images/hamburger-menu.svg';
import closeX from '../images/menu-close-x.svg';
import tinderLogo from '../images/tinder.png';
import tinderFlameLogo from '../images/tinder-flame.png';

class Header extends PureComponent {
  render() {
    const {
      menuOpen, onHamburgerButtonClick, scrollIntoView, showTitle, tinderUrl
    } = this.props;
    const offset = 60;

    return (
      <header>
        <nav
          itemScope
          itemType="https://schema.org/SiteNavigationElement"
        >
          <div className="tinderLogo">
            <a rel="noreferrer noopener" href={tinderUrl} target="_blank">
              <img alt="Tinder logo" className="tinderLogo__desktop" src={tinderLogo} />
              <img alt="Tinder logo" className="tinderLogo__mobile" src={tinderFlameLogo} />
            </a>
          </div>
          <ul>
            <li itemProp="name">
              <button type="button" onClick={() => scrollIntoView('about', offset)}>
                <FormattedMessage id="about" />
              </button>
            </li>
            <li itemProp="name">
              <button type="button" onClick={() => scrollIntoView('action', offset)}>
                <FormattedMessage id="takeAction" />
              </button>
            </li>
            <li itemProp="name">
              <button type="button" onClick={() => scrollIntoView('how', offset)}>
                <FormattedMessage id="howItWorks" />
              </button>
            </li>
            <li itemProp="name">
              <button type="button" onClick={() => scrollIntoView('chart', offset)}>
                <FormattedMessage id="factsAndFigures" />
              </button>
            </li>
          </ul>
          <CSSTransitionGroup
            transitionName="fade-in"
            transitionEnterTimeout={150}
            transitionLeaveTimeout={150}
          >
            {
              showTitle
                ? <h1>#representlove</h1>
                : null
            }
          </CSSTransitionGroup>
          <button type="button" className="hamburger-button" onClick={onHamburgerButtonClick}>
            {
              menuOpen
                ? <img alt="Close button" src={closeX} height="24px" width="24px" />
                : <img alt="Open nav button" src={hamburgerIcon} height="24px" width="24px" />
            }
          </button>
        </nav>
      </header>
    );
  }
}

Header.propTypes = {
  menuOpen: PropTypes.bool,
  onHamburgerButtonClick: PropTypes.func,
  scrollIntoView: PropTypes.func,
  showTitle: PropTypes.bool,
  tinderUrl: PropTypes.string
};

Header.defaultProps = {
  menuOpen: false,
  onHamburgerButtonClick: noop,
  scrollIntoView: noop,
  showTitle: true,
  tinderUrl: '#'
};

export default Header;
