const de = require('./de.json');
const en = require('./en.json');
const es = require('./es.json');
const fr = require('./fr.json');
const ko = require('./ko.json');
const pt = require('./pt.json');

module.exports = {
  de,
  en,
  es,
  fr,
  ko,
  pt
};
