import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import i18nMessages from '../data/messages';
import 'intl';

import '../styles/reset.css';
import '../styles/index.scss';
import Meta from './Meta';
import { defaultLangKey } from '../data/languages';

const Layout = ({
  children, locale
}) => (
  <IntlProvider
    locale={locale}
    messages={i18nMessages[locale]}
  >
    <div>
      <Meta locale={locale} />
      {children}
    </div>
  </IntlProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string
};

Layout.defaultProps = {
  locale: defaultLangKey
};

export default Layout;
