import React from 'react';
import PropTypes from 'prop-types';

import config from '../data/config';

const VideoOverlay = ({ lang, onOverlayClick }) => (
  <button type="button" className="overlay-backdrop" onClick={onOverlayClick}>
    <iframe
      className="video"
      src={`${config.EmbedVideoUrl}${config.VideoId[lang]}?rel=0&autoplay=1`}
      frameBorder="0"
      allow="encrypted-media"
      allowFullScreen
      title="Interracial Couple Emoji Project video"
    />
  </button>
);

VideoOverlay.propTypes = {
  lang: PropTypes.string.isRequired,
  onOverlayClick: PropTypes.func.isRequired
};

export default VideoOverlay;
