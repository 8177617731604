/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { PureComponent } from 'react';
import { navigate } from 'gatsby';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';

import config from '../data/config';
import { defaultLangKey } from '../data/languages';
import Header from './Header';
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import VideoOverlay from './VideoOverlay';

import chartDesktop from '../images/online_dating_interracial_dating-desktop.png';
import chartMobile from '../images/online_dating_interracial_dating-mobile.png';
import coupleEmoji from '../images/interracial.png';
import coupleEmoji2 from '../images/online_dating_2.png';
import emojiGif from '../images/interracial_dating.gif';
import videoPlaceholder from '../images/best_dating_apps.jpg';

class HomePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      showTitleInNav: false,
      showVideoOverlay: false
    };

    // refs
    this.sections = {};
  }

  componentDidMount() {
    if (this.scroller) {
      this.scroller.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.scroller) {
      this.scroller.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleHamburgerMenuClick = () => {
    const { menuOpen } = this.state;
    this.setState({
      menuOpen: !menuOpen
    });
  };

  handleVideoPlaceholderClick = () => {
    this.setState({
      showVideoOverlay: true
    });
  };

  handleVideoOverlayClick = () => {
    this.setState({
      showVideoOverlay: false
    });
  };

  handleScroll = () => {
    this.setState({
      showTitleInNav: this.scroller.scrollTop > 200
    });
  }

  handleTweetClick = (url) => {
    // Height and width specified by https://dev.twitter.com/web/intents
    const width = 550;
    const height = 520;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    window.open(
      url,
      'intent',
      `width=${width},height=${height},personalbar=0,toolbar=0,location=1,scrollbars=1,resizable=1,left=${left},top=${top}`
    );
  }

  scrollIntoView = (sectionName, offset) => {
    const section = this.sections[sectionName];
    this.scroller.scrollTop = section.offsetTop - offset;
    navigate(`#${sectionName}`);
    (section?.querySelector('h2') || section)?.focus();
    this.setState({
      menuOpen: false
    });
  };

  render() {
    const { menuOpen, showTitleInNav, showVideoOverlay } = this.state;
    const { intl, lang, localeSetUsingDefault } = this.props;
    const localizedTinderUrl = lang === localeSetUsingDefault || !lang ? config.TinderUrl : `${config.TinderUrl}/${lang}`;
    const tinderUrl = `${localizedTinderUrl}?utm_source=emoji`;

    return (
      <>
        <Header
          menuOpen={menuOpen}
          onHamburgerButtonClick={this.handleHamburgerMenuClick}
          scrollIntoView={this.scrollIntoView}
          showTitle={showTitleInNav}
          tinderUrl={tinderUrl}
        />
        <CSSTransitionGroup
          transitionName="fade-in"
          transitionEnterTimeout={150}
          transitionLeaveTimeout={150}
        >
          {
            menuOpen
              ? <MobileMenu scrollIntoView={this.scrollIntoView} />
              : null
          }
        </CSSTransitionGroup>
        <div className="scroller" ref={(ref) => { this.scroller = ref; }}>
          <main className="page">

            <section id="intro" className="section-intro" tabIndex={-1}>
              <h1>#representlove</h1>
              <h3><FormattedMessage id="interracialCoupleEmojiProject" /></h3>
              <img
                className="emojiGif"
                src={emojiGif}
                alt={intl.formatMessage({ id: 'interracialCouplesEmoji' })}
              />
            </section>

            <section id="subheader" className="section-subheader" tabIndex={-1}>
              <h4><FormattedMessage id="supportProject" /></h4>
              <a className="button" rel="noreferrer noopener" href={config.ChangeOrgUrl[lang]} target="_blank">
                <FormattedMessage id="signNow" />
              </a>
            </section>

            <button type="button" className="section-video" onClick={this.handleVideoPlaceholderClick}>
              <img
                className="video-placeholder"
                src={videoPlaceholder}
                alt={intl.formatMessage({ id: 'bestDatingApps' })}
              />
              <span className="play-button">
                <div className="play-button play-button__circle" />
                <svg className="play-button play-button__arrow" viewBox="0 0 72 72">
                  <g fill="none" fillRule="evenodd">
                    <path fill="#FEFEFE" d="M31.2 26.9V45l15.6-9.1z" />
                  </g>
                </svg>
              </span>

            </button>

            <section id="about" ref={(ref) => { this.sections.about = ref; }} tabIndex={-1}>
              <article>
                <h2 tabIndex={-1}><FormattedMessage id="about" /></h2>
                <div className="text-content">
                  <p><FormattedMessage id="aboutSection1" /></p>
                  <p><strong><FormattedMessage id="aboutSection2" /></strong></p>
                  <p><FormattedMessage id="aboutSection3" /></p>
                  <p>
                    <FormattedMessage
                      id="aboutSection4"
                      values={{
                        tinder: <a rel="noreferrer noopener" className="link inline" href={tinderUrl} target="_blank">Tinder</a>
                      }}
                    />
                  </p>
                </div>
              </article>
            </section>

            <section
              className="section-action"
              id="action"
              ref={(ref) => { this.sections.action = ref; }}
              tabIndex={-1}
            >
              <article>
                <h2 tabIndex={-1}><FormattedMessage id="takeAction" /></h2>
                <div className="text-content">
                  <ol>
                    <li className="bullet">
                      <div className="bullet__number">1</div>
                      <div className="bullet__instructions">
                        <h5><FormattedMessage id="joinTheCause" /></h5>
                        <p className="subheading">
                          <FormattedMessage
                            id="actionSection1"
                            values={{
                              petitionLink: <a rel="noreferrer noopener" className="link" href={config.ChangeOrgUrl[lang]} target="_blank">change.org</a>
                            }}
                          />
                        </p>
                      </div>
                      <a className="button" href={config.ChangeOrgUrl[lang]} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="signNow" />
                      </a>
                    </li>
                    <li className="bullet">
                      <div className="bullet__number">2</div>
                      <div className="bullet__instructions">
                        <h5><FormattedMessage id="spreadTheWord" /></h5>
                        <p className="subheading">
                          <FormattedMessage
                            id="actionSection2"
                            values={{
                              hashtag: <strong>#representlove</strong>
                            }}
                          />
                        </p>
                      </div>
                      <button
                        className="button"
                        type="button"
                        onClick={() => this.handleTweetClick(`${config.TweetUrl}?via=tinder&hashtags=representlove&url=${config.ShareVideoUrl}${config.VideoId[lang]}`)}
                      >
                        <FormattedMessage id="shareNow" />
                      </button>
                    </li>
                  </ol>
                </div>
              </article>
            </section>

            <section className="split-screen" id="split-screen" tabIndex={-1}>
              <div className="split-screen__half split-screen__half--white">
                <img
                  className="emoji"
                  src={coupleEmoji2}
                  alt={intl.formatMessage({ id: 'interracialDating' })}
                />
              </div>
              <div className="split-screen__half">
                <img
                  className="emoji"
                  src={coupleEmoji}
                  alt={intl.formatMessage({ id: 'interracialDating' })}
                />
              </div>
            </section>

            <section id="how" ref={(ref) => { this.sections.how = ref; }} tabIndex={-1}>
              <article>
                <h2 tabIndex={-1}><FormattedMessage id="howItWorks" /></h2>
                <div className="text-content">
                  <h5><FormattedMessage id="howItWorksSection1" /></h5>
                  <p><FormattedMessage id="howItWorksSection2" /></p>
                  <p><FormattedMessage id="howItWorksSection3" /></p>
                </div>
              </article>
            </section>

            <section id="chart" ref={(ref) => { this.sections.chart = ref; }} tabIndex={-1}>
              <article className="chart">
                <h2 tabIndex={-1}><FormattedMessage id="factsAndFigures" /></h2>
              </article>
              <figure className="chart__figure">
                <img
                  className="chart__figure__img chart__figure__img--mobile"
                  src={chartMobile}
                  alt={intl.formatMessage({ id: 'onlineDatingStudy' })}
                />
                <img
                  className="chart__figure__img chart__figure__img--desktop"
                  src={chartDesktop}
                  alt={intl.formatMessage({ id: 'onlineDatingStudy' })}
                />
                <figcaption><FormattedMessage id="chartSectionCaption" /></figcaption>
              </figure>
              <article className="chart">
                <div className="text-content">
                  <h5><FormattedMessage id="onlineDatingAndInterracialMarriages" /></h5>
                  <p className="subheading"><FormattedMessage id="theyGoHandInHand" /></p>
                  <p>
                    <FormattedMessage
                      id="chartSection1"
                      values={{
                        study: <a rel="noreferrer noopener" className="link" href={config.StudyUrl} target="_blank"><FormattedMessage id="study" /></a>
                      }}
                    />
                  </p>
                  <p><FormattedMessage id="chartSection2" /></p>
                  <p>
                    <FormattedMessage
                      id="chartSection3"
                      values={{
                        onlineDating: <a rel="noreferrer noopener" className="link inline" href={tinderUrl} target="_blank"><FormattedMessage id="onlineDating" /></a>
                      }}
                    />
                  </p>
                  <p><FormattedMessage id="chartSection4" /></p>
                  <p><FormattedMessage id="chartSection5" /></p>
                  <ul className="study-bullets">
                    <li><FormattedMessage id="chartBullet1" /></li>
                    <ul>
                      <li><FormattedMessage id="chartBullet2" /></li>
                    </ul>
                    <li><FormattedMessage id="chartBullet3" /></li>
                    <li><FormattedMessage id="chartBullet4" /></li>
                    <ul>
                      <li><FormattedMessage id="chartBullet5" /></li>
                    </ul>
                  </ul>
                  <p>
                    <FormattedMessage
                      id="forMoreInformation"
                      values={{
                        emailLink: <a className="link" href="mailto:press@gotinder.com">press@gotinder.com</a>
                      }}
                    />
                  </p>
                </div>
              </article>
            </section>

          </main>
          <Footer tinderUrl={tinderUrl} />
          {
            showVideoOverlay
              ? <VideoOverlay onOverlayClick={this.handleVideoOverlayClick} lang={lang} />
              : null
          }
        </div>
      </>
    );
  }
}

HomePage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  lang: PropTypes.string,
  localeSetUsingDefault: PropTypes.bool
};

HomePage.defaultProps = {
  intl: {},
  lang: defaultLangKey,
  localeSetUsingDefault: true
};

export default injectIntl(HomePage);
