import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import config from '../data/config';

import twitterIcon from '../images/twitter-icon.svg';
import instagramIcon from '../images/instagram-icon.svg';

class Footer extends PureComponent {
  render() {
    const { tinderUrl } = this.props;
    const d = new Date();
    const fullYear = d.getFullYear();

    return (
      <footer itemScope itemType="https://schema.org/Organization">
        <a rel="noreferrer noopener" className="link white" href={tinderUrl} target="_blank" itemProp="url">
          {`© ${fullYear} `}
          <FormattedMessage id="tinderCopyright" />
        </a>
        <div className="social-links">
          <a href={`${config.TinderInstagramUrl}`} target="_blank" rel="noopener noreferrer" itemProp="sameAs">
            <img alt="Instagram" src={instagramIcon} />
          </a>
          <a href={`${config.TinderTwitterUrl}`} target="_blank" rel="noopener noreferrer" itemProp="sameAs">
            <img alt="Twitter" src={twitterIcon} />
          </a>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  tinderUrl: PropTypes.string.isRequired
};

export default Footer;
