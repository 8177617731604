module.exports = {
  langs: [
    'de',
    'en',
    'es',
    'fr',
    'ko',
    'pt'
  ],
  defaultLangKey: 'en'
};
