/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import 'what-input';

import { defaultLangKey } from '../data/languages';
import HomePage from './HomePage';
import Layout from './Layout';

const Page = ({ pageContext }) => (
  <Layout locale={pageContext.locale} localeSetUsingDefault={pageContext.localeSetUsingDefault}>
    <HomePage lang={pageContext.locale} localeSetUsingDefault={pageContext.localeSetUsingDefault} />
  </Layout>
);

Page.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    localeSetUsingDefault: PropTypes.bool
  })
};

Page.defaultProps = {
  pageContext: {
    locale: defaultLangKey,
    localeSetUsingDefault: true
  }
};

export default Page;
