const config = {
  ChangeOrgUrl: {
    de: 'https://www.change.org/p/emojis-interkulturelle-paare',
    en: 'https://www.change.org/p/unicode-let-s-make-the-interracial-couple-emoji-happen',
    es: 'https://www.change.org/p/unicode-let-s-make-the-interracial-couple-emoji-happen',
    fr: 'https://www.change.org/p/unicode-le-projet-d-emoji-pour-les-couples-mixtes',
    ko: 'https://www.change.org/p/everyone-%EA%B5%AD%EC%A0%9C-%EC%BB%A4%ED%94%8C%EC%9D%84-%ED%91%9C%ED%98%84%ED%95%98%EB%8A%94-%EC%9D%B4%EB%AA%A8%ED%8B%B0%EC%BD%98%EC%9D%84-%EC%B0%BE%EC%8A%B5%EB%8B%88%EB%8B%A4',
    pt: 'https://www.change.org/p/unicode-emoji-de-casal-inter-racial'
  },
  VideoId: {
    de: 'G-rCg7YN3bs',
    en: 'tWrKrn7t2wo',
    es: 'tWrKrn7t2wo',
    fr: 'C6MKchpuYBE',
    ko: 'ammosB3pbyM',
    pt: 'estyoUkvGGQ'
  },
  EmbedVideoUrl: 'https://www.youtube.com/embed/',
  ShareVideoUrl: 'https://youtu.be/',
  StudyUrl: 'https://arxiv.org/abs/1709.10478',
  TinderInstagramUrl: 'https://www.instagram.com/tinder',
  TinderTwitterUrl: 'https://twitter.com/Tinder',
  TinderUrl: 'https://tinder.com',
  TweetUrl: 'https://twitter.com/intent/tweet'
};

export default config;
