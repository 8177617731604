import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import 'intl';

import '../styles/reset.css';
import '../styles/index.scss';
import { defaultLangKey, langs } from '../data/languages';

import poppinsRegular from '../fonts/poppins/Poppins-Regular.ttf';
import poppinsMedium from '../fonts/poppins/Poppins-Medium.ttf';
import poppinsBold from '../fonts/poppins/Poppins-Bold.ttf';
import proximaNovaBold from '../fonts/proximanova/proximanova-bold.woff2';
import faviconSmall from '../images/favicon-16x16.png';
import faviconLarge from '../images/favicon-32x32.png';
import emojiImage from '../images/interracial.png';

const Meta = ({ locale }) => {
  const getUrlFromLocale = useCallback((localeInput, hideDirIfDefault) => {
    const hideDir = !localeInput || (hideDirIfDefault && localeInput === defaultLangKey);
    return `https://emoji.tinder.com${hideDir ? '' : `/${localeInput}`}`;
  }, []);

  return (
    <Helmet
      htmlAttributes={{ lang: locale }}
      title="Tinder | Interracial Couple Emoji Project"
      meta={[
        { name: 'charset', content: 'utf-8' },
        { name: 'description', content: 'Online dating apps and interracial couples — they go hand in hand.' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
        { name: 'copyright', content: 'Tinder, Inc., ALL RIGHTS RESERVED' },
        { name: 'keywords', content: 'Interracial,Couple,Emoji,Project,Couples,Online,Dating,App,Free' },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: 'Tinder | Interracial Couple Emoji Project' },
        { name: 'twitter:description', content: 'Online dating apps and interracial couples — they go hand in hand.' },
        { name: 'twitter:site', content: '@Tinder' },
        { name: 'twitter:image', content: emojiImage },

        { property: 'og:title', content: 'Tinder | Interracial Couple Emoji Project' },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: 'Online dating apps and interracial couples — they go hand in hand.' },
        { property: 'og:url', content: 'https://emoji.tinder.com' },
        { property: 'og:image', content: emojiImage }
      ]}
      link={[
        {
          rel: 'icon', type: 'image/png', href: faviconSmall, sizes: '16x16'
        },
        {
          rel: 'icon', type: 'image/png', href: faviconLarge, sizes: '32x32'
        },
        {
          rel: 'preload', as: 'font', type: 'font/ttf', crossOrigin: 'crossorigin', href: poppinsRegular
        },
        {
          rel: 'preload', as: 'font', type: 'font/ttf', crossOrigin: 'crossorigin', href: poppinsMedium
        },
        {
          rel: 'preload', as: 'font', type: 'font/ttf', crossOrigin: 'crossorigin', href: poppinsBold
        },
        {
          rel: 'preload', as: 'font', type: 'font/woff2', crossOrigin: 'crossorigin', href: proximaNovaBold
        },
        {
          rel: 'canonical', href: getUrlFromLocale(locale, true)
        },
        {
          rel: 'alternate', hreflang: 'x-default', href: getUrlFromLocale(locale, false)
        },
        ...langs.map((currentLocale) => (
          { rel: 'alternate', hreflang: currentLocale, href: getUrlFromLocale(currentLocale, false) }
        ))
      ]}
    >
      <script async type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: 'https://emoji.tinder.com',
          name: 'Tinder | Interracial Couple Emoji Project',
          applicationCategory: 'Lifestyle',
          image: emojiImage,
          operatingSystem: 'any'
        })}
      </script>
    </Helmet>
  );
};

Meta.propTypes = {
  locale: PropTypes.string.isRequired
};

export default Meta;
